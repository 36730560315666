import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../layout/layout";
import { StaticImage } from "gatsby-plugin-image";

import Card from "../components/card";
import SEO from "../components/seo";
import FormattedText from "../components/portableText";

export const query = graphql`
    query WorkshopsCopy {
        sanityGlobal(_id: { eq: "f4d0bdaa-f5e1-4bee-b8a5-4df771781c3e" }) {
            _rawWorkshopIntro
        }
        allSanityWorkshop {
            edges {
                node {
                    workshop_url {
                        current
                    }
                    workshop_date
                    workshop_leader
                    workshop_name
                    workshop_short_desc
                    workshop_description {
                        children {
                            text
                        }
                    }
                    workshop_image {
                        asset {
                            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
                        }
                    }
                    tags {
                        project_tag
                    }
                    subject {
                        subject_tag
                    }
                }
            }
        }
    }
`;

const Workshops = ({ data }) => {
    useEffect(() => {
        if (data.allSanityWorkshop) {
            document.body.classList.add("workshops");
        }
        let filterButtons = [...document.getElementsByClassName("filterButton")];
        filterButtons.forEach(function (item) {
            if (item.id === currentTag) {
                item.classList.add("active");
            } else {
                item.classList.remove("active");
            }
        });
    });

    const urlPath = "/workshop/";

    var bgColor = {
        backgroundColor: "#c5fd25",
    };

    const [tag, updateTag] = useState("all-cards");

    let currentTag = tag;

    let tagArray = [];
    data.allSanityWorkshop.edges.map((workshop) =>
        workshop.node.tags.forEach((tag) => {
            if (!tagArray.includes(tag.project_tag)) {
                tagArray.push(tag.project_tag);
            }
        })
    );
    tagArray.sort();

    let subjectArray = [];
    data.allSanityWorkshop.edges.map((workshop) =>
        workshop.node.subject.forEach((sub) => {
            if (!subjectArray.includes(sub.subject_tag)) {
                subjectArray.push(sub.subject_tag);
            }
        })
    );
    subjectArray.sort();

    let defaultList = tagArray.concat(subjectArray);
    let filterList = [];

    const handleClick = (e) => {
        let item = e.target.id.toLowerCase();
        if (!filterList.includes(item)) {
            filterList.push(item);
        } else if (filterList.includes(item)) {
            filterList.splice(item, 1);
        }
    };

    console.log(defaultList + " default tags");

    const cards = data.allSanityWorkshop.edges;

    const filteredCards = [];
    if (currentTag.includes("all-cards")) {
        cards.forEach((card) => {
            card.node.tags.forEach((tagOnCard) => {
                filteredCards.push(card);
            });
        });
    } else {
        cards.forEach((card) => {
            card.node.tags.forEach((tagOnCard) => {
                console.log("tagOnCard", tagOnCard);
                if (tagOnCard.project_tag === currentTag) {
                    filteredCards.push(card);
                }
            });
        });
    }

    return (
        <Layout>
            <SEO title="Climate Workshops | Project Change | UEA" canonical="workshops" />
            <div className="workshops-header">
                <StaticImage src="../images/hero-image.png" alt="Climate Workshops" />
                <div className="container relative mx-auto text-center">
                    <div className="w-full sm:w-2/3 mx-auto pt-20 pb-6 sm:pb-16">
                        <h1>Workshops</h1>
                    </div>
                </div>
            </div>
            {data.sanityGlobal._rawWorkshopIntro ? (
                <div className="container mx-auto">
                    <div className="w-full sm:w-2/3 lg:w-1/2 text-center mx-auto mt-6 mb-24">
                        <FormattedText blocks={data.sanityGlobal._rawWorkshopIntro} />
                    </div>
                </div>
            ) : null}
            <div className="container mx-auto flex flex-wrap justify-center">
                {tagArray.map((tag) => (
                    <button
                        className="filterButton"
                        id={tag}
                        name={tag}
                        onClick={() => updateTag(tag)}
                    >
                        {tag}
                    </button>
                ))}
                <button
                    className="filterButton"
                    id="all-cards"
                    onClick={() => updateTag("all-cards")}
                >
                    All
                </button>
            </div>
            <div className="container mx-auto flex flex-wrap justify-center">
                {subjectArray.map((subject) => (
                    <button
                        className="filterButton"
                        id={subject}
                        name={subject}
                        onClick={handleClick}
                    >
                        {subject}
                    </button>
                ))}
                <button
                    className="filterButton"
                    id="all-cards"
                    onClick={() => updateTag("all-cards")}
                >
                    All
                </button>
            </div>
            <main id="main" className="p-6">
                {data.sanityGlobal._rawCompetitionIntro ? (
                    <div className="container mx-auto">
                        <div className="w-full sm:w-2/3 lg:w-1/2 text-center mx-auto mt-6 mb-24">
                            <FormattedText blocks={data.sanityGlobal._rawWorkshopIntro} />
                        </div>
                    </div>
                ) : null}
                <div className="container w-full sm:w-11/12 md:w-5/6 mx-auto flex flex-wrap">
                    {filteredCards.map((workshop, i) => (
                        <Card
                            class="workshop card w-full sm:w-1/2 px-3 mb-6"
                            imagedata={
                                workshop.node.workshop_image
                                    ? workshop.node.workshop_image.asset.gatsbyImageData
                                    : null
                            }
                            alt={workshop.node.workshop_name}
                            title={workshop.node.workshop_name}
                            wstags={workshop.node.tags.project_tag}
                            duration={workshop.node.workshop_length}
                            leader={workshop.node.workshop_leader}
                            excerpt={workshop.node.workshop_short_desc}
                            link={urlPath + workshop.node.workshop_url.current}
                            anchor="Read more  >"
                            bgcolor={bgColor}
                        />
                    ))}
                </div>
            </main>
        </Layout>
    );
};

export default Workshops;
